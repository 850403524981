@media only screen and (min-width:1440px){
  .home {
    display: flex;
    justify-content: center;
  }

  .home .contents{
    max-width: 1440px;
    width: 100%;
  }
}
