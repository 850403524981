/*! Generated by Font Squirrel (https://www.fontsquirrel.com) on April 28, 2019 */



@font-face {
    font-family: 'gt_walsheimCnBdOb';
    src: url('gt-walsheim-condensed-bold-oblique-webfont.woff2') format('woff2'),
         url('gt-walsheim-condensed-bold-oblique-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'gt_walsheimcondensed_bold';
    src: url('gt-walsheim-condensed-bold-webfont.woff2') format('woff2'),
         url('gt-walsheim-condensed-bold-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'gt_walsheimCnRgOb';
    src: url('gt-walsheim-condensed-regular-oblique-webfont.woff2') format('woff2'),
         url('gt-walsheim-condensed-regular-oblique-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'gt_walsheimcondensed_regular';
    src: url('gt-walsheim-condensed-regular-webfont.woff2') format('woff2'),
         url('gt-walsheim-condensed-regular-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'apercubold_italic';
    src: url('apercu_bold_italic.woff2') format('woff2'),
    url('apercu_bold_italic.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'apercubold';
    src: url('apercu_bold.woff2') format('woff2'),
    url('apercu_bold.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'apercuitalic';
    src: url('apercu_italic.woff2') format('woff2'),
    url('apercu_italic.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'aperculight_italic';
    src: url('apercu_light_italic.woff2') format('woff2'),
    url('apercu_light_italic.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'aperculight';
    src: url('apercu_light.woff2') format('woff2'),
    url('apercu_light.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'apercumedium_italic';
    src: url('apercu_medium_italic.woff2') format('woff2'),
    url('apercu_medium_italic.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'apercumedium';
    src: url('apercu_medium.woff2') format('woff2'),
    url('apercu_medium.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'apercu_monoregular';
    src: url('apercu_mono.woff2') format('woff2'),
    url('apercu_mono.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'apercuregular';
    src: url('apercu_regular.woff2') format('woff2'),
    url('apercu_regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'apercuregular';
    src: url('apercu.woff2') format('woff2'),
    url('apercu.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}