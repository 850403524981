.how-it-works{
  padding-bottom: 50px;
}

.how-it-works .title{
  font-family: gt_walsheimcondensed_bold;
  font-size: 50px;
  line-height: 63px;
  text-align: center;
  color: white;
  position: relative;
  width: 100%;
}

.how-it-works .list-aligner{
  width: 100%;
  position: relative;
  display:flex;
  justify-items: center;
  justify-content: center;
}

.how-it-works .list{
  font-family: 'apercubold';
  font-size: 22px;
  line-height: 55px;
  letter-spacing: 0.02em;
  text-align: left;
  color: white;
}

.how-it-works .background{
  width: 100%;
  height: 634px ;
}

.relative{
  position: relative;
}

.how-it-works .diagonal-box {
  background-color: #7139D8;
  margin-top: 50px;
  padding: 5% 20px; /* Added a percentage value for top/bottom padding to keep the wrapper inside of the parent */

  -webkit-transform: skewY(-5deg);
  -moz-transform: skewY(-5deg);
  -ms-transform: skewY(-5deg);
  -o-transform: skewY(-5deg);
  transform: skewY(-5deg);
}

.how-it-works.no-campaigns .diagonal-box {
  margin-top: 0;
  padding-top: 100px;
}

.how-it-works .diagonal-box > .box-wrapper {
  -webkit-transform: skewY(5deg);
  -moz-transform: skewY(5deg);
  -ms-transform: skewY(5deg);
  -o-transform: skewY(5deg);
  transform: skewY(5deg);
}

@media only screen and (max-width:425px){
  .how-it-works .title{
    font-family: gt_walsheimcondensed_bold;
    font-size: 36px;
    line-height: 125.95%;
    text-align: center;
    position: relative;
    width: 100%;
  }

  .how-it-works .list{
    font-family: 'apercuregular';
    font-size: 17px;
    line-height: 130%;
    letter-spacing: 0.02em;
    text-align: left;
    color: white;
  }
}