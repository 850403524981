.confirm-pledge{
    background-color: #EDC862;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
}

.confirm-pledge .message{
    font-size: 70px;
    line-height: 63px;
    text-align: center;
    font-family: gt_walsheimcondensed_bold;
    color: black;
}
