.footer.ui.grid{
    background-color: black;
    margin: 0;
    color: white;
    padding-top: 20px;
    padding-bottom: 20px;

}

.footer .section-title{
    font-family: apercubold_italic;
    margin-bottom: 12px;
    font-size: 14px;
    line-height: 24px;
    text-decoration: underline;
}

.footer .section-item{
    font-family: apercuregular;
    line-height: 16px;
    font-size: 15px;
    padding: 4px 0;
}

.footer .section-item a{
    text-decoration: none;
    color: white;
}

@media only screen and (max-width:991px){

    .logo-container{
        display: flex;
        justify-content: center;
    }

    .footer .section-title{
        text-align: center;
    }

    .footer .section-item{
        text-align: center;
    }
}