.past-campaigns{
}

.past-campaigns .title-section.only{
  padding-top: 100px;
}
.past-campaigns .title-section{
  font-size: 50px;
  line-height: 63px;
  text-align: center;
  font-family: gt_walsheimcondensed_bold;

  padding-top: 60px;
  padding-bottom: 60px;
  background-color: #EDC862;
}

.past-campaigns .loading-section{
  height: 400px;
}

.ui.medium.image.campaign-charity-logo{
  width: 140px;
  height: 140px;
}

.past-campaigns .logo-container{
  width: 300px;
  height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.past-campaigns .details-container{
  width: 100%;
  display: flex;
  align-items: center;
}

.past-campaigns .details{
  font-size: 23px;
  line-height: 37px;
  font-family: gt_walsheimcondensed_bold;
}

.past-campaigns .pledge-campaign-row{
  display: flex;
  border: 1px solid #A8A8A8;
  cursor: pointer;
}

.past-campaigns .pledge-campaign-row.first{
  background-color: #E8EAEC;
}

@media only screen and (max-width:425px){
  .past-campaigns .title-section{
    font-size: 36px;
    line-height: 125.95%;
    text-align: center;
    font-family: gt_walsheimcondensed_bold;

    padding-top: 30px;
    padding-bottom: 30px;
    background-color: #EDC862;
  }

  .past-campaigns .pledge-campaign-row {
    display: flex;
    flex-flow: column;
    border: 1px solid #A8A8A8;
    padding-top: 20px;
    padding-bottom: 20px;
  }

  .past-campaigns .logo-container {
    width: auto;
    height: auto;
    padding-bottom: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .past-campaigns .details-container {
    width: 100%;
    display: block;
  }

  .past-campaigns .details{
    padding-right: 30px;
    padding-left: 30px;
    font-size: 17px;
    line-height: 160.3%;
    font-family: gt_walsheimcondensed_bold;

  }
}