.thank-you{
    background-color: #EDC862;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
    padding: 20px;
}

.thank-you .message{
    font-size: 70px;
    line-height: 63px;
    text-align: center;
    font-family: gt_walsheimcondensed_bold;
    color: black;
}

.thank-you .sub-message{
    padding-top: 10px;
    font-size: 38px;
    line-height: 50px;
}

.thank-you .redirect{
    font-size: 28px;
}
