.campaign-stats{
  padding-top: 113px;
  padding-bottom: 64px;
}

.campaign-stats .subtitle{
  font-family: apercuregular;
  font-size: 22px;
  line-height: 30px;
  text-align: center;

  color: #E15534;
}

.stat-title{
  font-family: apercubold;
  font-size: 40px;
  line-height: 55px;
  text-align: center;

  color: #E15534;
}

.stat-number{
  font-family: gt_walsheimcondensed_bold;
  font-size: 90px;
  line-height: 113px;
  text-align: center;
  letter-spacing: 0.02em;

  color: #E15534;
}


@media only screen and (max-width:425px){
  .campaign-stats .subtitle{
    font-family: apercuregular;
    font-size: 15px;
    line-height: 136.73%;
    text-align: center;

    color: #E15534;
  }

  .stat-title{
    font-family: apercubold;
    font-size: 17px;
    line-height: 136.73%;
    text-align: center;

    color: #E15534;
  }

  .stat-number{
    font-family: gt_walsheimcondensed_bold;
    font-size: 45px;
    line-height: 125.95%;
    text-align: center;
    letter-spacing: 0.02em;
    color: #E15534;
  }

  .campaign-stats{
    padding-top: 30px;
    padding-bottom: 15px;
  }
}
