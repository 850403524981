
.give-away .give-away-title{
    padding-top: 60px;
    padding-bottom: 60px;
    font-size: 50px;
    line-height: 63px;
    text-align: center;
    font-family: gt_walsheimcondensed_bold;
}

.give-away .grid{
    background-color: black;
}

.give-away .ui.grid>.column:not(.row){
    padding:0;
}

.give-away .message-aligner{
    height: 100%;
    display: flex;
    align-items: center;
    background-color: black;
    padding:10%;
}

.give-away .message{
    font-family: apercuregular;
    font-size: 25px;
    line-height: 34px;
    color: white;
    text-align: left;
}

.give-away .ui.grid.content-grid{
    margin: 0;
}

.give-away .date{
    white-space: nowrap;
}

@media only screen and (max-width:425px){
    .give-away .give-away-title{
        padding-top: 30px;
        padding-bottom: 30px;
        font-size: 36px;
        line-height: 125.95%;
        text-align: center;
        font-family: gt_walsheimcondensed_bold;
    }

    .give-away .message{
        font-family: apercuregular;
        font-size: 17px;
        line-height: 23px;
        color: white;
        text-align: left;
    }
}