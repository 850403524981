
.campaign-details .title{
  font-family: 'gt_walsheimcondensed_bold';
  font-size: 35px;
  line-height: 40px;
  text-align: center;
  letter-spacing: 0.03em;
  max-width: 1440px;
  margin-left: 160px;
  min-height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.campaign-details{
  padding-top: 79px;
}

.campaign-details .ui.medium.image.charity-logo{
  width: 140px;
  height: 140px;
  position: absolute;
  top: -551px;
  left: 13px;
}
.campaign-details .ui.medium.image.charity-logo-wrapper{
  width: 170px;
  height: 170px;
  position: absolute;
  top: -568px;
  left: -2px;
  background-color: white;
}

.campaign-details .pledge-cover-overlay{
  width: 100%;
  height: 498px;
  background: #2E2E2E;
  opacity: 0.45;
  position: absolute;
  top: -496px;
  z-index: 0;
}

.campaign-details .pledge-cover.ui.image{
  width: 100%;
  height: 498px;
  object-fit: cover;
  z-index:0;
}

.campaign-details .pledge-cover-message{
  font-family: 'apercubold';
  font-size: 25px;
  line-height: 34px;
  text-align: center;
  color:white;
  text-shadow: 0px 1px 4px rgba(0, 0, 0, 0.15);
}

.campaign-details .cover-message-aligner.ui.grid{
  position: absolute;
  display: block;
  top: -486px;
  left: 10px;
  height: 498px;
  width: 100%;
}

.campaign-details .pledge-closing-message{
  font-family: apercubold_italic;
  font-size: 20px;
  line-height: 27px;
  text-align: center;
  letter-spacing: 0.03em;
  color: white;
  text-shadow: 0px 1px 1px rgba(0, 0, 0, 0.1);
  padding-top: 11px;
  padding-bottom: 24px;
}

.pledge-button.ui.button{
  font-family: apercubold;
  font-size: 23px;
  color: white;
  background: #20C26D;
  border-radius: 38.5px;
  width: 239px;
  height: 50px;
  text-align: center;
  margin-top: 37px;
  line-height: 0;
}

.campaign-details .details-below{
  color:white;
  opacity: 0.75;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  font-size: 20px;
  line-height: 25px;
  text-align: center;
  font-family: apercubold_italic;
}

.campaign-details .message-content-wrapper{
  display: flex;
  height: 100%;
  align-items: flex-end;
}

.relative{
  position: relative;
}

.center-h{
  display: flex;
  justify-content: center;
}


@media only screen and (max-width:425px){
  .campaign-details .title{
    font-family: 'gt_walsheimcondensed_bold';
    font-size: 20px;
    line-height: 18px;
    letter-spacing: 0.03em;
    text-align: left;
    min-height: 63px;
    justify-content: left;
    margin-left: 120px;
  }

  .campaign-details .ui.medium.image.charity-logo{
    width: 100px;
    height: 100px;
    position: absolute;
    top: -551px;
    left: 11px;
  }
  .campaign-details .ui.medium.image.charity-logo-wrapper{
    width: 120px;
    height: 120px;
    position: absolute;
    top: -560px;
    left: 0px;
    background-color: white;
  }

  .campaign-details .pledge-cover-message{
    font-family: 'apercuregular';
    font-size: 17px;
    line-height: 136.73%;
    text-align: left;
    color:white;
    text-shadow: 0px 1px 4px rgba(0, 0, 0, 0.15);
  }

  .campaign-details .details-below{
    color:white;
    opacity: 0.75;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    text-align: left;
    font-size: 17px;
    line-height: 21px;
    font-family: "apercuregular";
    padding-top: 10px;
  }

  .campaign-details .pledge-closing-message{
    font-family: "apercuregular";
    font-size: 17px;
    line-height: 27px;
    text-align: left;
    letter-spacing: 0.03em;
    color: white;
    text-shadow: 0px 1px 1px rgba(0, 0, 0, 0.1);
    padding-top: 11px;
    padding-bottom: 24px;
  }

  .campaign-details{
  }
}