@media only screen and (min-width:1440px){
  .pledge-campaign {
    display: flex;
    justify-content: center;
  }

  .pledge-campaign .contents{
    max-width: 1440px;
  }
}
