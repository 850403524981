.navbar.menu .item{
  font-size: 17px;
  line-height: 19px;
}

.navbar.menu{
  margin: 0;
  z-index: 999999;
  position: fixed;
  width: 100%;
}

.navbar .desktop{
  visibility: visible;
}
.navbar .mobile{
  visibility: hidden;
  width: 0;
  height: 0;
}

.navbar .mobile-sidebar{
  visibility: hidden;
  position: absolute;
}


@media only screen and (max-width:425px){

}

@media only screen and (max-width:540px){
  .navbar .desktop{
    visibility: hidden;
    width: 0;
    height: 0;
  }

  .navbar .mobile-sidebar{
    visibility: visible;
    position: absolute;
    right: 0;
    top: 79px;
    z-index: 100000;
  }

  .navbar.ui.menu .item.mobile{
    visibility: visible;
    width: auto;
    height: auto;
    position: absolute;
    right: 0;
    top: 10px;
    font-size: 22px;
  }
  .navbar .mobile-sidebar .content.closed{
    visibility: hidden;
    width: 0;
    height: 0;
  }
  .navbar .mobile-sidebar .content.open{
    visibility: visible;
    position: fixed;
    top: 79px;
    width: auto;
    height: auto;
    z-index: 10000;
    left: calc(100% - 210px);
  }
}
