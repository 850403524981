.donor-list{
  height: 770px;
  background-color: #E15534;
  margin-top: 80px;
}

.donor-list .circle{
  height: 25px;
  width: 25px;
  background-color: #bbb;
  border-radius: 50%;
  display: inline-block;
}

.donor-list .combined-pledges{
  position: relative;
  width: 265px;
  height: 195px;
  top:-150px;
}
.donor-list .combined-pledges .text{
  font-family: gt_walsheimcondensed_bold;
  font-size: 35px;
  line-height: 44px;
  text-align: center;
  letter-spacing: 0.02em;
  color: white;
  position: absolute;
  width: 195px;
  top:50px;
  left:30px;
}

.donor-list .combined-pledges-wrapper{
  display: flex;
  justify-content: center;
}

.donor-list .combined-pledges .text .text-sm{
  display: block;
  font-family: gt_walsheimcondensed_regular;
}
.donor-list .combined-pledges .circle{
  height: 195px;
  width: 195px;
  background-color: black;
  position: absolute;
  top:0;
  left:30px;
}

.donor-list .combined-pledges .combined-message{
  font-family: apercuregular;
  font-size: 22px;
  line-height: 30px;
  text-align: center;
  letter-spacing: 0.02em;
  color: white;
  top:200px;
  position: absolute;
}

.donor-list .list-content-wrapper{
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: center;
}

.donor-list .donor-table{
  padding-left: 20px;
  padding-right: 20px;
  max-width: 434px;
  max-height: 269px;

  border-radius: 11px;
  overflow-y: auto;
}

.donor-list .donor-table .ui.table tr td, .donor-list .donor-table .ui.table {
  border: none;
}

.donor-list .donor-table .amount-cell{
  text-align: right;
}

.donor-list .donor-table .donor-row{
  font-family: apercubold;
  font-size: 16px;
  line-height: 40px;
}

.donor-list .thankyou-title{
  font-family: gt_walsheimcondensed_bold;
  font-size: 50px;
  line-height: 63px;
  text-align: center;
  color: white;
  padding-bottom: 50px;
}








.donor-list .estimated-total{
  position: relative;
  width: 320px;
  height: 255px;
  top:150px;
}
.donor-list .estimated-total .text{
  font-family: gt_walsheimcondensed_bold;
  font-size: 55px;
  line-height: 69px;
  text-align: center;
  letter-spacing: 0.02em;
  color: black;
  position: absolute;
  width: 195px;
  top:93px;
  left:46px;
}

.donor-list .estimated-total-wrapper{
  display: flex;
  justify-content: center;
}

.donor-list .estimated-total .circle{
  height: 255px;
  width: 255px;
  background-color: #EDC862;
  position: absolute;
  top:0;
  left:30px;
}

.donor-list .estimated-total .estimated-total-message{
  font-family: apercuregular;
  font-size: 22px;
  line-height: 30px;
  text-align: center;
  letter-spacing: 0.02em;
  color: white;
  top:260px;
  position: relative;
}

.donor-list .donor-table-column{
  height: 100%;
}

.donor-list .ui.grid{
  height: 100%;
}

.donor-list .combined-pledges-wrapper{
  visibility: visible;
  width: auto;
  height: auto;
}

.donor-list .estimated-total-wrapper{
  visibility: visible;
  width: auto;
  height: auto;
}

@media only screen and (min-width:426px) and (max-width:991px){
  .donor-list .combined-pledges{
    top:0;
    height: 230px;
  }

  .donor-list .estimated-total{
    top:0;
  }

  .donor-list {
    height: 1100px;
  }

  .donor-list.empty {
    height: 900px;
  }

  .donor-list .donor-table-column{
    height: auto;
  }

  .donor-list .ui.grid{
    height: auto;
  }

  .donor-list.empty .combined-pledges-wrapper{
    padding-top: 30px;
  }

}

@media only screen and (max-width:425px){
  .donor-list .combined-pledges-wrapper{
    padding: 0;
    width: 0;
    height: 0;
    visibility: hidden;
  }

  .donor-list .estimated-total-wrapper{
    padding: 0;
    width: 0;
    height: 0;
    visibility: hidden;
  }
  .donor-list{
    height: auto;
  }

  .donor-list .thankyou-title{
    font-family: gt_walsheimcondensed_bold;
    font-size: 36px;
    line-height: 125.95%;
    text-align: center;
    color: white;
    padding-bottom: 50px;
  }

  .donor-list.empty .thankyou-title{
    font-family: gt_walsheimcondensed_bold;
    font-size: 36px;
    line-height: 125.95%;
    text-align: center;
    color: white;
    padding-bottom: 25px;
  }

}