.pledge-title{
  font-family: 'gt_walsheimcondensed_bold';
  font-size: 50px;
  line-height: 63px;
  text-align: center;
}

.pledge-form .form-aligner{
  display: flex;
  flex-flow: column;
  justify-content: left;
}

.pledge-amount-per-mile{
  padding-top: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.pledge-amount-per-mile .field.amount .ui.input{
  border: 1px solid #CDCDCD;
  box-sizing: border-box;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 3px;
  height: 79px;
  width: 124px;
}

.pledge-amount-per-mile .field.amount{
  height: 79px;
  width: 124px;
}

.pledge-amount-per-mile .field.amount .ui.input input{
  font-family: gt_walsheimcondensed_bold;
  font-size: 45px;
  text-align: center;
  padding: 0;
}

.pledge-cents{
  font-family: gt_walsheimcondensed_bold;
  font-size: 45px;
  line-height: 57px;
  padding-left: 10px;
}

.pledge-per-mile-message{
  font-family: gt_walsheimcondensed_bold;
  font-size: 30px;
  line-height: 38px;
  padding-left: 20px;
}

.pledge-estimate{
  font-family: apercuregular;
  font-size: 23px;
  line-height: 31px;
  text-align: center;
  padding-top: 16px;
}

.ui.form .field.pledge-text-input .ui.input{
  max-width: 434px;
  width: 100%;
  height: 37px;
  border: 1px solid #B7B7B7;
  box-sizing: border-box;
  box-shadow: 0px 0px 2px rgba(101, 101, 101, 0.25);
  border-radius: 1px;
  margin-top: 10px;
  margin-bottom: 10px;
}

.ui.form .field.pledge-text-input .ui.input input{
  font-family: apercuregular;
  font-size: 16px;
  line-height: 22px;
  text-align: left;
  padding: 5px;
}

.pledge-full-name{
  padding-top: 16px;
}

.pledge-checkbox.ui.checkbox{
  font-family: apercuregular;
  font-size: 16px;
  line-height: 22px;
  padding-top: 5px;
  padding-bottom: 5px;
}

.pledge-checkbox.ui.checkbox input{
  border: 1px solid #979797;
  box-sizing: border-box;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
}

.pledge-form .pledge-button.ui.button{
  margin-top: 26px;
}

.pledge-form .pledge-inputs{
  width: fit-content;
}

.pledge-form{
  margin-left: 30px;
  margin-right: 30px;
}

@media only screen and (max-width:425px){
  .pledge-title{
    font-family: 'gt_walsheimcondensed_bold';
    font-size: 36px;
    line-height: 125.95%;
    text-align: center;
  }

  .pledge-cents{
    font-family: gt_walsheimcondensed_bold;
    font-size: 40px;
    line-height: 125.95%;
    padding-left: 10px;
  }

  .pledge-per-mile-message{
    font-family: gt_walsheimcondensed_bold;
    font-size: 26px;
    line-height: 125.95%;
    padding-left: 20px;
  }
  .pledge-estimate{
    font-family: apercuregular;
    font-size: 17px;
    line-height: 21px;
    text-align: center;
    padding-top: 16px;
  }
}